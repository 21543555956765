<div class="row pl-3 pb-3 order-stats mx-0">
  <div class="col-md-6 col-xl-4 pl-0">
    <div class="row no-gutters ribbonContainer">
      <div class="col-5">
        <p class="ribbonHeader mt-2">{{"orderHistoryPage.orderTotal" |cxTranslate}}</p>
        <p class="ribbonText mt-2">{{"orderHistoryPage.totalValueSpent" |cxTranslate}}</p>
      </div>
      <div class="col-7 leftBorder">
        <div *ngIf="totalList?.length > 0 && !isQuotation else noData" class="mt-4 mb-4">
          <ng-container *ngFor="let data of totalList">
            <p class="ribbonHeader mb-3" *ngIf="data?.total > 0"> {{ data.total | currencyPrice }} 
              <span [ngClass]="{'currency-symbol': data['active']}"> {{ data.currency | currencyFormat }}</span>
            </p>
          </ng-container>
        </div>
        <ng-template #noData>
          <div class="leftBorder"><p class="ribbonHeader">-</p></div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-xl-4 pl-0">
    <div class="row no-gutters ribbonContainer">
      <div class="col-7">
        <p class="ribbonHeader mt-2"># {{"orderHistoryPage.orders" |cxTranslate}}</p>
        <p class="ribbonText mt-2">{{"orderHistoryPage.totalNumberOf" |cxTranslate}}</p>
      </div>
      <div class="col-5 leftBorder">
        <p class="ribbonHeader">{{ totalOrders | async }}</p>
      </div>
    </div>
  </div>
</div>