import { Component } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-order-history-summary',
  templateUrl: './order-history-summary.component.html',
  styleUrl: './order-history-summary.component.scss'
})
export class OrderHistorySummaryComponent {

  public totalOrders: Observable<any>;
  public totalList: any[];
  public makeActive = false;
  public isQuotation = false;

  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    this.totalOrders = this.globalService.orderSummary$.pipe(map(data => {
      let stringCheck: string = data?.currentQuery?.query?.value;
      this.isQuotation = stringCheck?.includes("isQuotation:true");
      this.totalList = data?.totals ? data?.totals.map(item => {
        let index = this.getIndexCurrency(item.currency);
        item['active'] = index == 0 || index == 2;
        return item;
      }) : [];
      return data?.pagination?.totalResults;
    }));
  }

  getIndexCurrency(currency) {
    let currencies = ["EUR", "DKK", "USD", "GBP", "CAD"];
    return currencies.indexOf(currency);
  }

}
